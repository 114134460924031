import React, { useRef, useState, useEffect } from 'react';
import ReactPageflip from 'react-pageflip';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
//
import './Book.scss';
import { Button, ButtonConstants } from './../button';

export default function Book({
  slug,
  numPages,
  ariaLabels,
  ariaLabelsMobile,
  endPage,
}) {
  const containerRef = useRef();
  const flipBookRef = useRef();
  const swiper = useRef();
  const arrowLeftRef = useRef();
  const arrowRightRef = useRef();

  const [currentSelected, setCurrentSelected] = useState(0);
  const [mobileActiveIndex, setMobileActiveIndex] = useState(0);
  const [firstInteraction, setFirstInteraction] = useState(false);

  const handleResize = () => {
    document.getElementsByClassName(
      'flip-book'
    )[0].style.minHeight = `${Math.ceil(
      containerRef.current.getBoundingClientRect().width / 2
    )}px`;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    swiper.current = new Swiper('.swiper-container', {
      slidesPerView: 1,
      on: {
        slideChange: () => {
          setMobileActiveIndex(swiper.current.activeIndex);
        },
      },
    });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const paginate = direction => {
    if (direction === 'left') {
      if (currentSelected > 0) {
        setCurrentSelected(currentSelected - 1);
        flipBookRef.current.getPageFlip().flipPrev();
        arrowLeftRef.current.blur();
        arrowLeftRef.current.focus();
      }
    } else {
      setCurrentSelected(currentSelected + 1);
      flipBookRef.current.getPageFlip().flipNext();

      if (!firstInteraction) setFirstInteraction(true);
      arrowRightRef.current.blur();
      arrowRightRef.current.focus();
    }
  };

  const renderPages = () => {
    const pages = [];

    for (let i = 0; i < numPages; i++) {
      if (i !== numPages - 1)
        pages.push(
          <div key={i} className='flip-page'>
            <img src={`/img/books/${slug}/${i}.jpg`} alt='' draggable={false} />
          </div>
        );
      else
        pages.push(
          <div key={'end-page'} className='flip-page end-page'>
            <h5 className='end-page-title color-text-plum'>
              continue the{' '}
              <span className='color-text-grapefruit'>adventure.</span>
            </h5>
            {endPage.links.map((item, index) => {
              return (
                <div className='item' key={index}>
                  <div className='item-image'>
                    <img src={item.asset} alt={item.altText} />
                  </div>
                  <div className='item-info'>
                    <h6 className='eyebrow color-text-grapefruit'>
                      {item.eyebrow}
                    </h6>
                    <h5 className='title color-text-plum'>{item.title}</h5>
                    <Button
                      label={item.buttonLabel}
                      type={ButtonConstants.TYPE[item.linkType]}
                      link={item.buttonURL}
                      theme={ButtonConstants.THEMES.PRIMARY}
                      ariaLabel={item.ariaLabel}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
    }

    return pages;
  };

  const renderSlides = () => {
    const slides = [];

    for (let i = 0; i < numPages; i++) {
      if (i !== numPages - 1)
        slides.push(
          <div className='swiper-slide' key={i}>
            <img src={`/img/books/${slug}/${i}.jpg`} alt='' />
          </div>
        );
      else
        slides.push(
          <div key={'end-page'} className='swiper-slide end-page'>
            <h5 className='color-text-plum'>
              continue the{' '}
              <span className='color-text-grapefruit'>adventure.</span>
            </h5>
            {endPage.links.map((item, index) => {
              return (
                <div className='item' key={index}>
                  <h6 className='color-text-grapefruit'>{item.eyebrow}</h6>
                  <h5 className='color-text-plum'>{item.title}</h5>
                  <Button
                    label={item.buttonLabel}
                    type={ButtonConstants.TYPE[endPage.linkType]}
                    link={item.buttonURL}
                    theme={ButtonConstants.THEMES.PRIMARY}
                  />
                </div>
              );
            })}
          </div>
        );
    }

    return slides;
  };

  return (
    <div
      className={`Book ${!firstInteraction ? 'unopened' : ''}`}
      ref={containerRef}
      onClick={() => {
        if (!firstInteraction) {
          setFirstInteraction(true);
        }
      }}
    >
      <ReactPageflip
        className='flip-book'
        width={500}
        height={500}
        size={'stretch'}
        flippingTime={300}
        showCover={true}
        ref={flipBookRef}
        onFlip={number => {
          if (!firstInteraction) setFirstInteraction(true);
          setCurrentSelected(Math.floor((number.data + 1) / 2));
        }}
      >
        {renderPages()}
      </ReactPageflip>
      <div className='controls desktop'>
        <button
          ref={arrowLeftRef}
          className='arrow left'
          onClick={() => paginate('left')}
          aria-label={ariaLabels && ariaLabels[currentSelected]}
          disabled={currentSelected === 0}
          data-current={currentSelected}
        />
        <button
          ref={arrowRightRef}
          className='arrow right'
          onClick={() => paginate('right')}
          aria-label={ariaLabels && ariaLabels[currentSelected]}
          disabled={currentSelected === Math.floor(numPages / 2)}
        />
      </div>
      <div className='book-mobile swiper-container'>
        <div className='swiper-wrapper'>{renderSlides()}</div>
      </div>
      <div className='controls mobile SF'>
        <button
          className='arrow left'
          onClick={() => {
            swiper.current.slidePrev();
            setMobileActiveIndex(swiper.current.activeIndex);
          }}
          aria-label={ariaLabelsMobile && ariaLabelsMobile[mobileActiveIndex]}
        />
        <button
          className='arrow right'
          onClick={() => {
            swiper.current.slideNext();
            setMobileActiveIndex(swiper.current.activeIndex);
          }}
          aria-label={ariaLabelsMobile && ariaLabelsMobile[mobileActiveIndex]}
        />
      </div>
    </div>
  );
}
