import React from 'react';
import { Link } from 'gatsby';
//
import './../css/index.scss';
import './BookPage.scss';
import Head from './../components/head/Head';
import { Cell, Grid, GridContainer } from './../components/grid';
import Book from './../components/book/Book';
import Nav from './../components/nav/Nav';
import Footer from './../components/footer/Footer';
import { Button, ButtonConstants } from './../components/button';

export default function BookPage({ pageContext }) {
  const {
    slug,
    bookTitle,
    seoMetadata,
    readingTime,
    bookURL,
    quizURL,
    activitiesURL,
    shareURL,
    numPages,
    ariaLabels,
    ariaLabelsMobile,
    endPage,
    gameTout,
    bookTout,
  } = pageContext;

  return (
    <div className='BookPage'>
      <Head {...seoMetadata} />
      <GridContainer>
        <Nav />
        <main>
          <Grid className='body'>
            <Cell small={12} collapse>
              <div className='breadcrumb'>
                <Link to={'/'} className='text-cta'>
                  <span className='back-caret'></span>Back to Adventures with
                  Money
                </Link>
              </div>
            </Cell>
            <Cell small={12} collapse>
              <h1 className='book-title text-large-headline color-text-plum'>
                {bookTitle}
              </h1>
            </Cell>
            <Cell small={12} className='toolbar'>
              <div className='info'>
                <div className='reading-time'>{`Reading Time: ${readingTime}`}</div>
                <div className='items'>
                  <a
                    className='item'
                    href={bookURL}
                    target='_self'
                    download
                    rel='noopener noreferrer'
                  >
                    <div className='icon color-background-plum'>
                      <img src='/img/iconDownload.png' alt='download' />
                    </div>
                    <div>Book</div>
                  </a>
                  {quizURL && (
                    <a
                      className='item'
                      href={quizURL}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <div className='icon color-background-plum'>
                        <img src='/img/iconDownload.png' alt='download' />
                      </div>
                      <div>Quiz</div>
                    </a>
                  )}
                  {activitiesURL && (
                    <a
                      className='item'
                      href={activitiesURL}
                      target='_self'
                      download
                      rel='noopener noreferrer'
                    >
                      <div className='icon color-background-plum'>
                        <img src='/img/iconDownload.png' alt='download' />
                      </div>
                      <div>Activities</div>
                    </a>
                  )}
                </div>
              </div>
              <div className='share'>
                <div className='item label'>Share</div>
                <div className='item'>
                  <a
                    className='twitter share-icon color-background-medium-blue'
                    href={`https://www.twitter.com/share?url=${shareURL}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src='/img/iconTwitter.png' alt='twitter' />
                  </a>
                </div>
                <div className='item'>
                  <a
                    className='facebook share-icon color-background-medium-blue'
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                      shareURL
                    )}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src='/img/iconFacebook.png' alt='facebook' />
                  </a>
                </div>
                <div className='item'>
                  <a
                    className='email share-icon color-background-medium-blue'
                    href={`mailto:?subject=${bookTitle}&body=Parents and teachers can share this book with their children to help educate them on the fundamentals of budgeting, earning, smart saving and giving back to their communities. ${shareURL}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src='/img/iconEmail.png' alt='email' />
                  </a>
                </div>
              </div>
            </Cell>
            <Cell small={12} className='book-container'>
              <Book
                slug={slug}
                numPages={numPages}
                ariaLabels={ariaLabels}
                ariaLabelsMobile={ariaLabelsMobile}
                endPage={endPage}
              />
            </Cell>
          </Grid>
          {gameTout && (
            <Cell small={12} className={`game-tout ${gameTout.game}`}>
              <h2 className='title color-text-champagne'>{gameTout.title}</h2>
              <p className='description color-text-champagne'>
                {gameTout.description}
              </p>
              <div className='cta'>
                <Button
                  label={gameTout.buttonLabel}
                  type={ButtonConstants.TYPE.EXTERNAL}
                  link={gameTout.buttonURL}
                  theme={ButtonConstants.THEMES.INVERSE}
                />
              </div>
            </Cell>
          )}
          <Cell small={12} className='book-tout color-background-champagne'>
            <div className='book-image'>
              <img
                src={bookTout.bookAsset}
                alt={
                  bookTout.altText
                    ? bookTout.altText
                    : `Image of the book ${bookTout.title}`
                }
              />
            </div>
            <div className='book-info'>
              <h2 className='title color-text-plum'>
                {bookTout.title}
                <span className='color-text-grapefruit'>.</span>
              </h2>
              <p
                className='description'
                dangerouslySetInnerHTML={{ __html: bookTout.description }}
              ></p>
              <Button
                label={bookTout.buttonLabel}
                type={ButtonConstants.TYPE.INTERNAL}
                link={bookTout.buttonURL}
                theme={ButtonConstants.THEMES.PRIMARY}
                ariaLabel={bookTout.ariaLabel}
              />
            </div>
          </Cell>
        </main>
        <Footer />
      </GridContainer>
    </div>
  );
}
